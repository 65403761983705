import React, { useState } from "react";

export default function Test() {
  const [count, setCount] = useState(0);

  return (
    <div>
      <h3 onClick={() => setCount((i) => i + 1)}>
        this is a asynchrounously loaded component <br></br> with a counter:{" "}
        {count}
      </h3>
    </div>
  );
}
